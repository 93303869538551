<template>
    <div style="min-height:200px">
        <div class="mb-3">
            <div class="mt-1 mb-1 d-flex align-items-center">
            <router-link :to="'../../client/profile/'+idClientDataMain" class="d-flex align-items-center">
                <b-icon icon="arrow-left" class="mr-2"></b-icon>
                {{trans('to-profile',175)}}
            </router-link>
            </div>
        </div>
        <b-overlay :show="loadingList" :opacity="0.0">      
            <b-card class="mb-3">         
                    <b-row class="app-card-header">
                        <b-col start>
                            <div class="app-card-tittle">
                            <span v-if="!loadingList">{{clientName}}</span>
                            </div>
                        </b-col>
                        <b-col class="app-input-applicant-filter-width">
                            <b-form-input type="text" v-model="filters.search" :lazy="true" class="form-control" :placeholder="trans('search',3)" size="sm" />
                        </b-col>

                        <b-col cols="auto" end class="d-flex align-items-center">
                            <app-table-settings :filters="filters" :loadingList="loadingList" :items="items"/>  
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table responsive small hover select-mode="single" :fields="fields" :items="items.list" :sort-by.sync="filters.sortBy" :sort-desc.sync="filters.sortDesc" ref="selectableTable" show-empty>
                                <template #empty>
                                        <div class="d-flex justify-content-center align-items-center app-table-no-records">
                                            {{trans('no-records-to-display',180)}}
                                        </div>
                                </template>
                                <template #head(shortcut_name)="data">
                                    <app-column-filter :label="data.label" fieldName="user" :sortingObject="filters.sorting" :filteringObject="filters.filtering.shortcut_name"/>                             
                                </template>
                                <template #head(type_name)="data">
                                    <app-column-filter :label="data.label" fieldName="type_name" :sortingObject="filters.sorting" :filteringObject="filters.filtering.type_name"/>                             
                                </template>
                                <template #head(category_name)="data">
                                    <app-column-filter :label="data.label" fieldName="category_name" :sortingObject="filters.sorting" :filteringObject="filters.filtering.category_name"/>                             
                                </template>
                                <template #head(entry_date)="data">
                                    <app-column-filter :label="data.label" fieldName="entry_date" :sortingObject="filters.sorting"/>                             
                                </template>
                                <template #head(description)="data">
                                    <app-column-filter :label="data.label" fieldName="description" :sortingObject="filters.sorting"/>                             
                                </template>
                                
                                <template #cell(entry_date)="data">
                                    {{setFormatDate(data.item.entry_date)}}
                                </template>
                                <template #cell(shortcut_name)="data">
                                    <span class="avatar avatar-xs rounded-circle">{{(data.item.shortcut_name)}}</span>
                                </template>
    

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="auto" end v-if="!loadingList">
                            <app-column-filter-remove-all :filters="filters"/>
                        </b-col>
                        <div v-if="items.total > filters.perPage">                                                     
                            <b-pagination first-number last-number pills align="right" v-model="filters.page" :total-rows="items.total" :per-page="filters.perPage" size="sm"/>
                        </div>
                    </b-row>
                </b-card>
                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
    </div>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import AppColumnFilter from '@core/components/Table/AppColumnFilter.vue';
import AppColumnFilterRemoveAll from '@core/components/Table/AppColumnFilterRemoveAll.vue';
import AppTableSettings from '@core/components/Table/AppTableSettings.vue';

export default {
    components: {
        AppColumnFilter,
        AppColumnFilterRemoveAll,
        AppTableSettings
    },

    
    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            idClientDataMain: this.$route.params.id_client_data_main,
            mApplicationAdd: { show: false, args: null, result: 0, return: null },
            loadingFilters: false,
            loadingList: false,
            clientName: null,
            totalRows: 0,
            filters: {
                page: 1,
                perPage: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {                        
                        shortcut_name: {type: 'list', changed: 0, values: [], function: "getClientHistoryUserList", parameters: {id_client_data_main: this.$route.params.id_client_data_main }, widthClass:'app-width-min-350'},
                        category_name: {type: 'list', changed: 0, values: [], function: "getClientHistoryCategorieList", parameters: {id_client_data_main: this.$route.params.id_client_data_main }, widthClass:'app-width-min-350'},
                        type_name: {type: 'list', changed: 0, values: [], function: "getClientHistoryTypeList", parameters: {id_client_data_main: this.$route.params.id_client_data_main }, widthClass:'app-width-min-350'},
                },
                search: "",
                status: 1,
            },
            fields: [
                { key: "shortcut_name", label: this.trans('user',182), sortable: true, thStyle: { "min-width": "80px", "max-width": "80px" }, },
                { key: "entry_date", label: this.trans('entry-date',182), sortable: true, thStyle: { "min-width": "120px", "max-width": "120px" } },
              
                { key: "category_name", label: this.trans('category',182), sortable: true, thStyle: { width: "22%" } },
                { key: "type_name", label: this.trans('type',182), sortable: true, thStyle: { width: "22%" } },

                { key: "entry_date", label: this.trans('entry-date',182), sortable: true, thStyle: { width: "22%" } },
                { key: "description", label: this.trans('description',182), sortable: true, thStyle: { width: "33%" } },
                
            ],
            

            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loadingList = true;
            axios
                .post("clients/profile/history/getList", {
                    filters: JSON.stringify(this.filters),
                    id_client_data_main: this.idClientDataMain,
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.clientName = res.data.client;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loadingList = false;
                });
        },
        setFormatDate(eventDate) {
            return moment(eventDate).format("DD-MM-YYYY HH:mm"); 
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loadingList) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.shortcut_name.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.category_name.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.type_name.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

        }
    },
 
};
</script>